import React from 'react'
import { Link } from 'react-router-dom'
const AcademicCalendar = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Academic Calendar</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Academic Calendar  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <iframe width="100%" height="800px" src="https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/pdf/academic-calendar.pdf"></iframe>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default AcademicCalendar

