import React  from "react";
import { Link } from 'react-router-dom'  
const AlumniRegistration = () => { 
  return (
    <>
    
         {/* <div className="alumni-register">
          <p><Link to="https://sfsr.campussoft.net" target="_blank">  <div className="AlumniRegistrationbox"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/AlumniRegistration.png" alt="St. Francis School  Harmu, Ranchi "/></div></Link> </p>
          <p><Link to="https://sfsr.campussoft.net" target="_blank">  <span>Alumni Registration</span> </Link></p> 
       </div> */}

    </>
  )
}

export default AlumniRegistration
