import React  from 'react';  
const Slider = () => { 
  return (
    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
            <div  className="carousel-item active">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/slide1.jpg" className="d-block w-100" alt=" St. Francis School  Harmu, Ranchi " />
            </div> 
            <div  className="carousel-item">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/slide2.jpg" className="d-block w-100" alt=" St. Francis School  Harmu, Ranchi " />
          </div> 
          <div  className="carousel-item">
          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/slide3.jpg" className="d-block w-100" alt=" St. Francis School  Harmu, Ranchi " />
        </div> 
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Slider;
