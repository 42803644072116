import { useEffect, useState } from "react";
import React from 'react'
import { useNavigate, Link } from "react-router-dom"
import { getCircular } from "../Service/Api";

const Circulars = () => { 
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year);
        console.log("get current Year", Year);
     }, []);

    useEffect(() => {
        const fetchData = async () => {
            const circularData = await getCircular();
            console.log("circular data>>>",circularData); 
            setData(circularData);
        };
        fetchData();
    }, []);
  
    useEffect(() => {
        if(!data) {
            return;
        }
        console.log(selectedMonth, selectedYear, searchQuery);
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const year = new Date(item.date).getFullYear();
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedMonth) === month;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        setFilteredData(filteredData);
        const datas = filteredData.filter((div) => div.category === "Circulars");
        setFilteredData(datas);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    useEffect(() => {
        console.log('filterData', filterData)
    }, [filterData]);

    const handleModal = (id) => {
      navigate(`/SubGallery?id=${id}`);
    };
     

    return (
        <>
        <div className="innerslide">
        <ul className="breadcrumb">
             <li><Link to="/"> Home</Link> </li>  
             <li>Circulars</li>
        </ul>
    </div> 
    <div className="innersec">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Circulars  </h1>
                </div>
            </div>
            <div className="row tabs-dynamic">
            <div className="col-md-3">
                <div className="count-val">
                    <p>Total Count: {filterData.length}</p>
                </div>
            </div>
            <div className="col-md-3">
                <div className="month-selection">
                    <select 
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        className="yearSelection"
                    >
                        <option value={"All"}>All</option>
                        {yearRanges.map((yr) => (
                            <option key={yr} value={yr}>{yr}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="col-md-6">
                <div className="searchBox">
                    <input
                        type="text"
                        id="myInput"
                        name="name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search Here.."
                    />
                </div>
            </div>
        </div>
        <div className="row years">
            {filterData.length > 0 ? (
                filterData.map((item, index) => (
                  
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 videosearch" key={index}>
                        <div className="video-gallery">
                            <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} target="_blank">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/pdf.png" alt=" St. Francis School  Harmu, Ranchi " />
                                <h3>{item.title}</h3>
                            </Link>
                        </div>
                    </div>
                   
                ))
            ) : (
                <div>No Circulars found.</div>
            )}
        </div>
        </div>
    </div>
        </>
    );
}

export default Circulars;
