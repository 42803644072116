import React, { useEffect, useState } from 'react'
import {getGallery} from'../Service/Api'
import Slider from "react-slick"; 
const Gallery = () => {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(null);

   useEffect(() => {
       const fetchData = async () =>{
              try{
                 const galleryData = await getGallery();
                 setData(galleryData) ;  
              }catch(error){
               setError(error);
              }finally{
               setLoading(false)
              }
       }
       fetchData();
   },[]);

   const emptyArray = [
      { title:"Gallery Title",img:"https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/gal1.jpg"},
      { title:"Gallery Title",img:"https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/gal2.jpg"},
      { title:"Gallery Title",img:"https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/gal3.jpg"},
      { title:"Gallery Title",img:"https://d280nq1n4mqyso.cloudfront.net/api/image/STFSR/public/Images/gal4.jpg"}

   ]
  var settings = { 
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3, 
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2, 
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1, 
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
          }
        }
      ]
  };
  return (
     <>
     <Slider {...settings}>
     {data && data.length > 0 ? (data.map((item,index) => ( 
        <div className="item" key={index}>
          <div className="gallery-blk">
            <div className="gallery-img">
            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} alt="St. Francis School, Ranchi" />
                <div className="title">
                    <p> {item.title} </p>
                </div>
            </div>
           </div>
        </div> 

        ))) : (emptyArray.map((item,index) => (
          <div className="item" key={index}>
          <div className="gallery-blk">
            <div className="gallery-img">
            <img src={item.img} alt="St. Francis School, Ranchi" />
                <div className="title">
                    <p> {item.title} </p>
                </div>
            </div>
           </div>
        </div> 
        )))}
    </Slider>
     </>
  )
}

export default Gallery
